import axios from 'axios';

import { GET, POST, PUT, PATCH, DELETE } from '@/shared/constants/httpMethods';
import { SET_RESPONSE_DIALOG } from '@/shared/mutations/type';

export const api = async (method, url, body) => {
  const responseData = {
    statusCode: null,
    data: null,
  };

  let response = null;

  try {
    switch (method) {
      case GET:
        response = await axios.get(url, body);
        break;
      case POST:
        response = await axios.post(url, body);
        break;
      case PUT:
        response = await axios.put(url, body);
        break;
      case PATCH:
        response = await axios.patch(url, body);
        break;
      case DELETE:
        response = await axios.delete(url, body);
        break;
      default:
        break;
    }

    responseData.statusCode = response.status;
    responseData.data = response.data;
  } catch (error) {
    const errorObject = {
      visible: true,
      isSuccess: false,
      message: null,
      subMessage: null,
      errors: [],
    };

    if (error.code === 'ERR_NETWORK') {
      responseData.statusCode = 503;
      errorObject.message = 'Connection refused';
      errorObject.subMessage = 'Sorry, we are currently experiencing connection issues. Please try again later.';
    } else {
      responseData.statusCode = 400;
      errorObject.message = 'Error';
      errorObject.subMessage = 'Something went wrong with the request. Please try again later. If the issue persists, contact support for assistance.';
    }

    window.App.$store.commit(SET_RESPONSE_DIALOG, errorObject);
  }

  return responseData;
};
