<template>
  <v-dialog v-model="responseDialog.visible" max-width="500">
    <v-card :class="responseDialog.isSuccess ? 'response-success px-10 pt-10 pb-5' : 'response-error px-10 pt-10 pb-5'">
      <v-img v-if="responseDialog.isSuccess" class="mx-auto" :width="100" :height="100" :src="require('@/assets/checked.png')"></v-img>

      <v-img v-else class="mx-auto" :width="100" :height="100" :src="require('@/assets/cancel.png')"></v-img>

      <p class="text-center mt-5 text-h6">{{ message }}</p>

      <p class="text-body-1 font-weight-normal">
        {{ subMessage }}
      </p>

      <v-card-text>
        <div v-for="error in responseDialog.errors" :key="error" class="mb-4">
          <div class="font-weight-normal">
            <strong>{{ error.field }}</strong>
          </div>
          <div v-for="errorMessage in error.messages" :key="errorMessage">
            {{ errorMessage }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center mt-5">
        <v-btn color="blue" dark variant="flat" height="48" class="px-10" @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.response-success {
  border-top: 10px solid #32ba7c !important;
}

.response-error {
  border-top: 10px solid #e24c4b !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { CLOSE_RESPONSE_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'ResponseDialog',
  computed: {
    ...mapState(['responseDialog']),
    message() {
      if (this.responseDialog.message) return this.responseDialog.message;

      if (this.responseDialog.isSuccess) return 'Request Succeded';
      return 'Request Failed';
    },

    subMessage() {
      if (this.responseDialog.subMessage) return this.responseDialog.subMessage;

      if (this.responseDialog.isSuccess) return 'Your request has been completed successfully. Thank you for using our services. If you require further assistance, feel free to reach out to us.';
      return 'An unexpected error has occurred. Please try again later or contact support for assistance. We apologize for any inconvenience this may have caused.';
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit(CLOSE_RESPONSE_DIALOG);
    },
  },
};
</script>
