export const SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME';
export const SET_CONFIRMATION_DIALOG = 'SET_CONFIRMATION_DIALOG';
export const CLOSE_CONFIRMATION_DIALOG = 'CLOSE_CONFIRMATION_DIALOG';
export const SET_RESPONSE_DIALOG = 'SET_RESPONSE_DIALOG';
export const CLOSE_RESPONSE_DIALOG = 'CLOSE_RESPONSE_DIALOG';
export const SET_FILTER = 'SET_FILTER';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_META_DATA = 'SET_META_DATA';
export const SET_SORTING = 'SET_SORTING';
export const SET_REGISTRANTS = 'SET_REGISTRANTS';
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';
export const CONFIRM_USER = 'CONFIRM_USER';
export const DECLINE_USER = 'DECLINE_USER';
export const BLOCK_USER = 'BLOCK_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const ADD_NOTE = 'ADD_NOTE';
export const DELETE_USER = 'DELETE_USER';
