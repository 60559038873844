<template>
  <v-card :loading="isLoading" class="mb-2" elevation="1" outlined>
    <v-list-item three-line>
      <v-list-item-avatar rounded size="60" :color="color">
        <v-icon dark> {{ icon }} </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="text-overline">{{ title }}</div>
        <v-list-item-title class="text-h6 mb-1">{{ count }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: 'DailyStat',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
