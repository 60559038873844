import { api } from '@/services/httpCall';
import { GET, POST } from '@/shared/constants/httpMethods';
import { HANDS_ON_LAB, GET_IT_GIRL, CAPTURE_THE_FLAG, VIC_ONE } from '@/shared/constants/filters/invitations';

export const getRegistrantsByFilter = async (filter, { page, pageSize }, search, { sortColumn, sortDirection }) => {
  let endpoint = '/admin/v2/registrants?';

  for (let i = 0; i < filter.selectedYears.length; i += 1) {
    if (i === 0) endpoint += `year=${filter.selectedYears[i]}`;
    else endpoint += `&year=${filter.selectedYears[i]}`;
  }

  if (search !== null || search !== '') endpoint += `&search=${search}`;

  endpoint += `&length=${pageSize}`;
  endpoint += `&page=${page}`;

  if (sortColumn) {
    endpoint += `&sortColumn=${sortColumn}`;
    endpoint += `&sortDirection=${sortDirection}`;
  }

  if (filter.selectedAttendanceConfirmation) endpoint += `&attendanceConfirmation=${filter.selectedAttendanceConfirmation}`;

  if (filter.selectedSideTracks) {
    if (filter.selectedSideTracks.includes(HANDS_ON_LAB)) endpoint += '&IsHolRegistrant=true';
    if (filter.selectedSideTracks.includes(GET_IT_GIRL)) endpoint += '&IsGigRegistrant=true';
    if (filter.selectedSideTracks.includes(CAPTURE_THE_FLAG)) endpoint += '&IsCtfRegistrant=true';
    if (filter.selectedSideTracks.includes(VIC_ONE)) endpoint += '&IsVicOneRegistrant=true';
  }

  endpoint += `&emailConfirmed=${filter.selectedEmailStatus ?? ''}&role=${filter.selectedRole ?? ''}`;
  if (filter.selectedApplicationVerificationStatus) {
    endpoint += `&profileVerificationStatus=${filter.selectedApplicationVerificationStatus}&applicationStatus=${filter.selectedApplicationVerificationStatus}`;
  }

  endpoint += `&industry=${filter.selectedIndustry ?? ''}&function=${filter.selectedFunction ?? ''}&roleFunction=${filter.selectedfunctionRole ?? ''}`;
  if (filter.selectedpreviousAttendanceStatus) endpoint += `&isLastYearAttendee=${filter.selectedpreviousAttendanceStatus ?? ''}`;

  if (filter.selectedAccountStatus) endpoint += `&status=${filter.selectedAccountStatus}`;

  // endpoint += `&status=${filter.selectedAccountStatus ?? ''}&emailConfirmed=${filter.selectedEmailStatus ?? ''}&role=${filter.selectedRole ?? ''}`;
  // endpoint += `&isAttending=${filter.selectedAttendingStatus ?? ''}&isLastYearAttendee=${filter.selectedpreviousAttendanceStatus ?? ''}`;
  // endpoint += `&isAttendingAMSession=${filter.selectedSessions.includes(AM_SESSION) ? true : ''}`;
  // endpoint += `&isAttendingPMSession=${filter.selectedSessions.includes(PM_SESSION) ? true : ''}`;
  // endpoint += `&isAttendingPlenary=${filter.selectedSessions.includes(PLENARY) ? true : ''}`;

  const response = await api(GET, endpoint);
  return response.data ?? null;
};

export const getExportedRegistrants = async (filter) => {
  let endpoint = '/admin/registrants?';

  for (let i = 0; i < filter.selectedYears.length; i += 1) {
    if (i === 0) endpoint += `year=${filter.selectedYears[i]}`;
    else endpoint += `&year=${filter.selectedYears[i]}`;
  }

  endpoint += `&emailConfirmed=${filter.selectedEmailStatus ?? ''}&role=${filter.selectedRole ?? ''}`;
  if (filter.selectedApplicationVerificationStatus) {
    endpoint += `&profileVerificationStatus=${filter.selectedApplicationVerificationStatus}&applicationStatus=${filter.selectedApplicationVerificationStatus}`;
  }

  if (filter.selectedAttendanceConfirmation) endpoint += `&attendanceConfirmation=${filter.selectedAttendanceConfirmation}`;

  if (filter.selectedSideTracks) {
    if (filter.selectedSideTracks.includes(HANDS_ON_LAB)) endpoint += '&IsHolRegistrant=true';
    if (filter.selectedSideTracks.includes(GET_IT_GIRL)) endpoint += '&IsGigRegistrant=true';
    if (filter.selectedSideTracks.includes(CAPTURE_THE_FLAG)) endpoint += '&IsCtfRegistrant=true';
    if (filter.selectedSideTracks.includes(VIC_ONE)) endpoint += '&IsVicOneRegistrant=true';
  }

  endpoint += `&industry=${filter.selectedIndustry ?? ''}&function=${filter.selectedFunction ?? ''}&roleFunction=${filter.selectedfunctionRole ?? ''}`;
  if (filter.selectedpreviousAttendanceStatus) endpoint += `&isLastYearAttendee=${filter.selectedpreviousAttendanceStatus ?? ''}`;

  if (filter.selectedAccountStatus) endpoint += `&status=${filter.selectedAccountStatus}`;

  const response = await api(GET, endpoint);
  return response.data ?? null;
};

export const declineUserAttendance = async ({ id, reason, remarks }) => {
  const response = await api(POST, '/admin/decline', { id, reason, remarks });
  if (response.statusCode === 200) return id;
  return null;
};

export const blockUserAccount = async ({ id, reason, remarks }) => {
  const response = await api(POST, '/admin/block', { id, reason, remarks });
  if (response.statusCode === 200) return id;
  return null;
};

export const unblockUserAccount = async (id) => {
  const response = await api(POST, '/admin/unblock', { id });
  if (response.statusCode === 200) return id;
  return null;
};

export const deleteUserAccount = async ({ id, remarks }) => {
  const response = await api(POST, '/admin/purge', { id, remarks });
  if (response.statusCode === 200) return id;
  return null;
};

export const confirmUserAttendance = async (id) => {
  const response = await api(POST, '/admin/confirm', { id });
  if (response.statusCode === 200) return id;
  return null;
};

export const verifyDomain = async (email) => {
  try {
    const response = await api(GET, `/admin/verify-email-domain/${email}`);
    if (response.statusCode === 200) return true;
  } catch {
    return false;
  }

  return false;
};

export const getLogsByUserId = async (userId) => {
  const response = await api(GET, `/admin/events/${userId}`);
  if (response.statusCode === 200) return response.data;
  return [];
};

export const createUsers = async (users) => {
  const response = await api(POST, '/admin/v2/register-guest', { registrants: users, sendEmail: true, generateQr: true });
  if (response.statusCode === 200) return response.data;
  return null;
};
