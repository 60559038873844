import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
  },
  {
    path: '/registrant-list',
    name: 'RegistrantList',
    component: () => import('@/views/Registrant'),
  },
  {
    path: '/registrant-list/2023',
    name: 'RegistrantList2023',
    component: () => import('@/views/Archive2023/RegistrantList'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
