export default {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_MSAL_TENANT_ID}`,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
