<template>
  <div>
    <v-app-bar class="px-16">
      <v-img :src="require('@/assets/decode-admin.png')" alt="Trend Micro Inc. - Decode Admin Logo" max-height="150" max-width="250" contain></v-img>

      <v-spacer></v-spacer>

      <div class="d-flex justify-end align-center">
        <v-avatar color="grey">
          <v-icon dark> mdi-account </v-icon>
        </v-avatar>

        <div class="d-flex flex-column ml-3">
          <span class="text-caption font-weight-medium">{{ accountName }}</span>
          <span class="text-caption">Admin</span>
        </div>
      </div>

      <template v-slot:extension>
        <v-tabs align-with-title color="red" centered>
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab v-for="tab in tabs" :key="tab.title" :to="tab.link" color="red">
            <v-icon left> {{ tab.icon }} </v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PageHeader',
  data() {
    return {
      activeTab: '',
      tabs: [
        {
          icon: 'mdi-monitor-dashboard',
          title: 'Dashboard',
          link: '/',
        },
        {
          icon: 'mdi-clipboard-list-outline',
          title: 'Registrants',
          link: '/registrant-list',
        },
        // {
        //   icon: 'mdi-home-outline',
        //   title: 'One-Click Registrants',
        //   link: '/one-click-registrant-list',
        // },
      ],
    };
  },
  computed: {
    ...mapState(['accountName']),
  },
};
</script>

<style>
.border-bottom {
  border-bottom: 1px solid black !important;
}
</style>
