import axios from 'axios';

import VueApexCharts from 'vue-apexcharts';
import Vue from 'vue';
import App from './App';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_ENDPOINT}/api`;

Vue.config.productionTip = false;
Vue.prototype.$msalInstance = {};
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

const app = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

window.App = app;
