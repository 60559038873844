import { api } from '@/services/httpCall';
import { POST, GET } from '@/shared/constants/httpMethods';

export const addNote = async ({ userId, content }) => {
  const response = await api(POST, '/admin/note', { userId, content });
  if (response.statusCode === 200) return { userId, content };
  return null;
};

export const getNotesByUserId = async (userId) => {
  const response = await api(GET, `/admin/note/user/${userId}`);
  if (response.statusCode === 200) return response.data;
  return [];
};
