import { GET } from '@/shared/constants/httpMethods';
import { api } from '../httpCall';

export const getStatisticsByYear = async (year) => {
  let selectedYearParams = '';

  if (year === 'All') {
    const decodeYears = [2024, 2023, 2022, 2021, 2020, 2019, 2018];

    for (let i = 0; i < decodeYears.length; i += 1) {
      if (i === 0) selectedYearParams += `year=${decodeYears[i]}`;
      else selectedYearParams += `&year=${decodeYears[i]}`;
    }
  } else {
    selectedYearParams = `year=${year}`;
  }

  const response = api(GET, `/admin/stats?${selectedYearParams}`);

  return response;
};
