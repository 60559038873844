import Vue from 'vue';
import Vuex from 'vuex';

import { getRegistrantsByFilter, confirmUserAttendance, declineUserAttendance, blockUserAccount, unblockUserAccount, deleteUserAccount } from '@/services/modules/RegistrantService';
import { addNote } from '@/services/modules/NoteService';

import {
  SET_ACCOUNT_NAME,
  SET_CONFIRMATION_DIALOG,
  CLOSE_CONFIRMATION_DIALOG,
  SET_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG,
  SET_FILTER,
  SET_PAGINATION,
  SET_SEARCH,
  SET_META_DATA,
  SET_REGISTRANTS,
  SET_SORTING,
  SET_TABLE_LOADING,
  CONFIRM_USER,
  DECLINE_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  ADD_NOTE,
  DELETE_USER,
} from '@/shared/mutations/type';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountName: null,
    confirmationDialog: {
      visible: false,
      data: [],
      message: '',
      subMessage: '',
      onSubmit: null,
      onCancel: null,
    },
    responseDialog: {
      visible: false,
      isSuccess: false,
      message: null,
      subMessage: null,
      errors: [],
    },
    isLoading: false,
    registrants: [],
    filter: {},
    search: '',
    pagination: {
      page: 1,
      pageSize: 10,
    },
    metaData: {
      totalItems: 0,
      totalPages: 0,
    },
    sorting: {
      sortColumn: 'dateCreated',
      sortDirection: 'desc',
    },
  },
  mutations: {
    [SET_ACCOUNT_NAME](state, accountName) {
      state.accountName = accountName;
    },
    [SET_CONFIRMATION_DIALOG](state, confirmationDialog) {
      state.confirmationDialog = confirmationDialog;
    },
    [CLOSE_CONFIRMATION_DIALOG](state) {
      state.confirmationDialog.visible = false;
    },
    [SET_RESPONSE_DIALOG](state, responseDialog) {
      state.responseDialog = responseDialog;
    },
    [CLOSE_RESPONSE_DIALOG](state) {
      state.responseDialog.visible = false;
    },
    [SET_FILTER](state, filter) {
      state.filter = filter;
    },
    [SET_PAGINATION](state, { page, pageSize }) {
      state.pagination = { page, pageSize };
    },
    [SET_META_DATA](state, { totalPages, totalItems }) {
      state.metaData = { totalPages, totalItems };
    },
    [SET_SORTING](state, { sortBy, sortDesc }) {
      state.sorting = {
        sortColumn: sortBy,
        sortDirection: sortDesc ? 'desc' : 'asc',
      };
    },
    [SET_SEARCH](state, search) {
      state.search = search;
    },
    [SET_REGISTRANTS](state, registrants) {
      state.registrants = registrants;
    },
    [SET_TABLE_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [CONFIRM_USER](state, id) {
      const userIndex = state.registrants.findIndex((user) => user.userId === id);
      state.registrants[userIndex].isConfirmed = true;
      state.registrants[userIndex].isPending = false;
      state.registrants[userIndex].profileVerificationStatus = 'Approved';
      state.registrants[userIndex].applicationStatus = 'Approved';
    },
    [DECLINE_USER](state, { id, reason, remarks }) {
      const userIndex = state.registrants.findIndex((user) => user.userId === id);
      state.registrants[userIndex].isPending = false;
      state.registrants[userIndex].profileVerificationStatus = 'Declined';
      state.registrants[userIndex].applicationStatus = 'Declined';
      state.registrants[userIndex].rejectionReason = reason;
      state.registrants[userIndex].otherReason = remarks;
    },
    [BLOCK_USER](state, { id }) {
      const userIndex = state.registrants.findIndex((user) => user.userId === id);
      state.registrants[userIndex].isRejected = true;
    },
    [UNBLOCK_USER](state, id) {
      const userIndex = state.registrants.findIndex((user) => user.userId === id);
      state.registrants[userIndex].isRejected = false;
    },
    [DELETE_USER](state, { id }) {
      state.registrants = state.registrants.filter((user) => user.userId !== id);
    },
    [ADD_NOTE](state, { userId, content }) {
      const userIndex = state.registrants.findIndex((user) => user.userId === userId);
      state.registrants[userIndex].lastNote = content;
    },
  },
  actions: {
    async getRegistrantsByFilterAction({ commit, state }) {
      commit(SET_TABLE_LOADING, true);
      const response = await getRegistrantsByFilter(state.filter, state.pagination, state.search, state.sorting);

      if (response) {
        commit(SET_REGISTRANTS, response.data);
        commit(SET_META_DATA, { totalItems: response.totalItems, totalPages: response.totalPages });
      }

      commit(SET_TABLE_LOADING, false);
    },
    async confirmUserAttendanceAction({ commit }, id) {
      const response = await confirmUserAttendance(id);
      if (response) commit(CONFIRM_USER, id);
      return response;
    },
    async declineUserAttendanceAction({ commit }, form) {
      const response = await declineUserAttendance(form);
      if (response) commit(DECLINE_USER, form);
      return response;
    },
    async blockUserAccountAction({ commit }, form) {
      const response = await blockUserAccount(form);
      if (response) commit(BLOCK_USER, form);
      return response;
    },
    async unblockUserAccountAction({ commit }, id) {
      const response = await unblockUserAccount(id);
      if (response) commit(UNBLOCK_USER, id);
      return response;
    },
    async deleteUserAccountAction({ commit }, form) {
      const response = await deleteUserAccount(form);
      if (response) commit(DELETE_USER, form);
      return response;
    },
    async addNoteAction({ commit }, id) {
      const response = await addNote(id);
      if (response) commit(ADD_NOTE, id);
      return response;
    },
  },
});
