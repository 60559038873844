<template>
  <v-dialog persistent :value="value" width="500">
    <v-card :loading="loading">
      <template slot="progress">
        <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
      </template>
      <v-card-title>
        <slot name="header"></slot>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeConfirmationDialog');
    },
  },
};
</script>
