<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="text-overline font-weight-black mb-2">Daily Statistics</p>
        <v-row>
          <v-col> <daily-stat :is-loading="isLoading" :count="stats.todayApprovedApplicants" title="Today Approved" icon="mdi-check-circle" color="success"></daily-stat></v-col>
          <v-col> <daily-stat :is-loading="isLoading" :count="stats.todayDeclinedApplicants" title="Today Declined" icon="mdi-close-circle" color="error"></daily-stat></v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-space-between align-center mb-6">
          <v-row>
            <v-col cols="10">
              <p class="text-overline font-weight-black mb-2">Overall Statistics</p>
            </v-col>
            <v-col cols="2">
              <v-menu top close-on-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn id="selectedYear" color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon left>mdi-calendar-badge</v-icon>
                    Filter: {{ selectedOverallStatsYear }}
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(year, index) in years" :key="index">
                    <v-btn class="text-body-2 select-year-button" :class="{ 'blue--text text--darken-3': year === selectedOverallStatsYear }" text @click="getTotalStatsForYear(year)">
                      {{ year }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </div>

        <overall-stat :is-loading="isLoading" :items="totalStats"></overall-stat>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getStatisticsByYear } from '@/services';
import DailyStat from '@/components/Home/DailyStat';
import OverallStat from '@/components/Home/OverallStat';

export default {
  name: 'Home',
  components: {
    DailyStat,
    OverallStat,
  },
  data() {
    return {
      selectedOverallStatsYear: 'All',
      overallStatsYearChanged: false,
      years: ['All', 2024, 2023, 2022, 2021, 2020, 2019, 2018],
      isLoading: true,
      backendEndpoint: process.env.VUE_APP_BACKEND_ENDPOINT,
      stats: {
        todayConfirmed: 0,
        todayDeclined: 0,
      },
      totalStats: [],
    };
  },
  async created() {
    await this.getStats();
  },
  methods: {
    parseStats() {
      const parsedStats = Object.keys(this.stats).map((key) => ({ stat: key, count: this.stats[key] }));
      parsedStats.forEach((item) => {
        if (item.stat.includes('total')) {
          this.totalStats.push(item);
        }
      });
    },
    async getStats() {
      this.isLoading = true;
      const response = await getStatisticsByYear(this.selectedOverallStatsYear);
      if (response.data) {
        this.stats = response.data;
        this.parseStats();
      }

      this.isLoading = false;
    },
    async getTotalStatsForYear(year) {
      this.selectedOverallStatsYear = year;
      this.totalStats = [];
      this.overallStatsYearChanged = true;
      await this.getStats();
    },
  },
};
</script>
