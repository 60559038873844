<template>
  <v-dialog v-model="confirmationDialog.visible" transition="dialog-top-transition" persistent max-width="700">
    <v-card class="px-10 py-5">
      <v-card-text>
        <p class="mb-5 text-h6 font-weight-medium">Are you sure?</p>
        <p class="mb-3 text-body-1 font-weight-medium">
          {{ confirmationDialog.message }}
        </p>
        <p class="mb-4 text-body-1 font-weight-medium">
          {{ confirmationDialog.subMessage }}
        </p>

        <v-list lines="one">
          <v-list-item v-for="n in confirmationDialog.data" :key="n" :title="n.title" :subtitle="n.description"></v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn :disabled="isLoading" outlined @click="closeDialog">Cancel </v-btn>
        <v-btn :loading="isLoading" color="primary" @click="submitDialog">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { CLOSE_CONFIRMATION_DIALOG } from '@/shared/mutations/type';

export default {
  name: 'ConfirmationDialog',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['confirmationDialog']),
  },
  methods: {
    closeDialog() {
      this.$store.commit(CLOSE_CONFIRMATION_DIALOG);
      this.confirmationDialog.onCancel();
    },
    async submitDialog() {
      this.isLoading = true;
      await this.confirmationDialog.onSubmit();
      this.isLoading = false;
    },
  },
};
</script>
