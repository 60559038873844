<template>
  <v-card :loading="isLoading">
    <v-simple-table height="400px">
      <template v-slot:default>
        <thead>
          <tr>
            <th id="name" class="text-left">Type</th>
            <th id="count" class="text-left">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.stat | convertToSentenceCase }}</td>
            <td>{{ item.count }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'OverallStat',
  filters: {
    convertToSentenceCase(value) {
      if (value === 'totalAttendingAMSession') return 'Attending AM Session';
      if (value === 'totalAttendingPMSession') return 'Attending PM Session';
      if (value === 'totalRegisteredToVicOne') return 'Registered To VicOne';
      if (value === 'totalYesRsvp') return 'RSVP - YES';
      if (value === 'totalNoRsvp') return 'RSVP - NO';
      if (value === 'totalPendingRsvp') return 'RSVP - PENDING';
      const result = value.replace(/([A-Z])/g, ' $1');
      return (result.charAt(0).toUpperCase() + result.slice(1)).replace('Total ', '');
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
