<template>
  <div>
    <v-app>
      <page-header></page-header>
      <response-dialog></response-dialog>
      <confirmation-dialog></confirmation-dialog>
      <request-confirmation-dialog></request-confirmation-dialog>
      <v-dialog v-model="notAuthorizedDialog" persistent max-width="700">
        <v-card>
          <v-card-title class="text-h5"> Unauthorized Access </v-card-title>
          <v-card-text class="mt-3">
            <p class="text-subtitle-1 font-weight-medium">
              Your attempt to access this application has been denied. While you have successfully authenticated, your account lacks the necessary permissions to proceed further.
            </p>
            <p class="text-subtitle-1 font-weight-medium">
              Please ensure that you have been assigned the <strong>'Admin'</strong> role before attempting to access this application again. If you believe this to be an error, please contact the
              system administrator for assistance.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-main v-if="account" class="pt-10" style="background-color: #f8f9fa">
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import msal from '@/plugins/msal';

import { SET_ACCOUNT_NAME } from '@/shared/mutations/type';
import PageHeader from '@/components/Global/PageHeader';
import ResponseDialog from '@/components/Global/ResponseDialog';
import ConfirmationDialog from '@/components/Global/ConfirmationDialog';
import RequestConfirmationDialog from '@/components/Global/RequestConfirmationDialog';

export default {
  name: 'App',
  components: {
    PageHeader,
    ResponseDialog,
    ConfirmationDialog,
    RequestConfirmationDialog,
  },
  data: () => ({
    account: undefined,
    notAuthorizedDialog: false,
  }),
  async created() {
    this.$msalInstance = new PublicClientApplication(msal);
    await this.handleRedirect();
  },
  methods: {
    async handleRedirect() {
      await this.$msalInstance.handleRedirectPromise();
      const [account] = this.$msalInstance.getAllAccounts();

      if (!account) {
        await this.$msalInstance.loginRedirect({});
      }

      const { idTokenClaims } = account;

      if (idTokenClaims.roles.includes('Admin')) {
        const { accessToken } = await this.$msalInstance.acquireTokenSilent({
          account,
          scopes: [`api://${process.env.VUE_APP_MSAL_CLIENT_ID}/api.scope`],
        });

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        this.account = account;
        this.$store.commit(SET_ACCOUNT_NAME, this.account.name);
      } else {
        this.notAuthorizedDialog = true;
      }
    },
  },
};
</script>
